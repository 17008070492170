import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from '../layouts/layout'
import ProductDetail from '../components/ProductDetail'

const ProductPage = ({ pageContext }) => {
  return (
    <Layout>
      {() => (
        <>
          <Helmet>
            <meta name="keywords" content={pageContext.seoKeywords} />
            <meta name="description" content={pageContext.seoDescription} />
            <title>{pageContext.seoTitle}</title>
          </Helmet>
          <ProductDetail product={pageContext} />
          <script
            dangerouslySetInnerHTML={{
              __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window,document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '335401304272137');
            fbq('track', 'PageView');
          `,
            }}
          />
          <noscript>
            <img
              height="1"
              width="1"
              src="https://www.facebook.com/tr?id=335401304272137&ev=PageView&noscript=1"
            />
          </noscript>
        </>
      )}
    </Layout>
  )
}

export default ProductPage

import React from 'react'
import get from 'lodash/fp/get'
import map from 'lodash/fp/map'
import replace from 'lodash/fp/replace'
import { FaFileAlt } from 'react-icons/fa'

import ProductCell from '../ProductCell'
import { titleClass } from '../../utils/titleClass'
import { countPrice } from '../../lib/product'
import { promiseConfiguration } from '../../lib/configuration'

class ProductDetail extends React.Component {
  constructor(props) {
    super(props)
    const { product } = props
    const grammage =
      product && product.grammages ? `${product.grammages[0]}` : ''
    const format = product && product.formats ? product.formats[0] : ''
    this.state = {
      tab: 0,
      discountAmount: 0,
      discountPercent: 0,
      cartons: 1,
      grammage,
      format,
      ...countPrice(product, parseInt(grammage), format, 0, 1, 0, 0),
    }
  }

  componentDidMount() {
    promiseConfiguration().then(configuration =>
      this.setState(prevState => ({
        ...prevState,
        discountAmount: configuration.discountAmnount,
        discountPercent: configuration.discountPercent,
      }))
    )
  }

  render() {
    const { product } = this.props
    const { discountAmount, discountPercent, cartons, tab } = this.state
    return (
      <div className="w-100">
        <div className="fl w-50 w-100-m">
          <img alt={product.name} src={replace('http:', 'https:', product.image.url)} />
        </div>
        <div className="fr w-50 w-100-m">
          <div
            className={`productColumn__title pv3 ${titleClass[
              product.titleColor
            ] || titleClass.default}`}
          >
            <ProductCell>{product.name}</ProductCell>
          </div>
          <table className="fl w-100">
            <tbody>
              <tr>
                <td className="w-40-m">
                  <ProductCell right>Počet listů v balíčku</ProductCell>
                </td>
                <td className="w-60-m">
                  <ProductCell>{this.state.package}</ProductCell>
                </td>
              </tr>
              <tr>
                <td className="w-40-m">
                  <ProductCell right>Počet balíčků v krabici</ProductCell>
                </td>
                <td className="w-60-m">
                  <ProductCell>{this.state.carton}</ProductCell>
                </td>
              </tr>
              <tr>
                <td className="w-40-m">
                  <ProductCell right>Cena za balíček</ProductCell>
                </td>
                <td className="w-60-m">
                  <ProductCell>{`${this.state.packagePrice} Kč`}</ProductCell>
                </td>
              </tr>
              <tr>
                <td className="w-40-m">
                  <ProductCell right>Cena za 1000 listů</ProductCell>
                </td>
                <td className="w-60-m">
                  <ProductCell>{`${
                    this.state.thousandPcsPrice
                  } Kč`}</ProductCell>
                </td>
              </tr>
              <tr>
                <td className="w-40-m">
                  <ProductCell right>Vyberte gramáž</ProductCell>
                </td>
                <td className="w-60-m">
                  <ProductCell>
                    <select
                      style={{ width: '10em' }}
                      name="grammage"
                      value={this.state.grammage}
                      onChange={e => {
                        const grammage = parseInt(e.target.value)
                        this.setState(prevState => ({
                          ...prevState,
                          ...countPrice(
                            product,
                            grammage,
                            prevState.format,
                            prevState.amount,
                            prevState.cartons,
                            discountAmount,
                            discountPercent
                          ),
                        }))
                      }}
                    >
                      {product.grammages
                        .sort((a, b) => parseInt(a) - parseInt(b))
                        .map(_ => (
                          <option id={_} key={_}>
                            {_}
                          </option>
                        ))}
                    </select>
                  </ProductCell>
                </td>
              </tr>
              <tr>
                <td className="w-40-m">
                  <ProductCell right>Vyberte formát</ProductCell>
                </td>
                <td className="w-60-m">
                  <ProductCell>
                    <select
                      style={{ width: '10em' }}
                      name="format"
                      value={this.state.format}
                      onChange={e => {
                        const format = e.target.value
                        this.setState(prevState => ({
                          ...prevState,
                          ...countPrice(
                            product,
                            prevState.grammage,
                            format,
                            prevState.amount,
                            prevState.cartons,
                            discountAmount,
                            discountPercent
                          ),
                        }))
                      }}
                    >
                      {product.formats.sort().map(_ => (
                        <option id={_} key={_}>
                          {_}
                        </option>
                      ))}
                    </select>
                  </ProductCell>
                </td>
              </tr>
              <tr>
                <td className="w-40-m">
                  <ProductCell right>Zadejte počet krabic</ProductCell>
                </td>
                <td className="w-60-m">
                  <ProductCell>
                    <input
                      style={{ width: '10em' }}
                      type="number"
                      value={this.state.cartons}
                      onChange={e => {
                        const cartons = e.target.value
                        this.setState(prevState => ({
                          ...prevState,
                          cartons,
                          ...countPrice(
                            product,
                            prevState.grammage,
                            prevState.format,
                            prevState.amount,
                            cartons,
                            discountAmount,
                            discountPercent
                          ),
                        }))
                      }}
                    />
                  </ProductCell>
                </td>
              </tr>
              {discountAmount <= cartons && (
                <tr>
                  <td className="w-40-m green">
                    <ProductCell right>Sleva</ProductCell>
                  </td>
                  <td className="w-60-m">
                    <ProductCell>{`${Math.ceil(
                      (this.state.price / 100) * discountPercent
                    )} Kč`}</ProductCell>
                  </td>
                </tr>
              )}
              <tr>
                <td className="w-40-m">
                  <ProductCell right>Celková cena</ProductCell>
                </td>
                <td className="w-60-m">
                  <ProductCell>{`${this.state.price} Kč`}</ProductCell>
                </td>
              </tr>
            </tbody>
          </table>
          {discountAmount > cartons && (
            <h2 className="fl w-100 tc red">{`Při odběru dalších ${discountAmount -
              cartons} krabic sleva ${discountPercent}%`}</h2>
          )}
          <div className="fl w-100 tc pv3">
            <a
              className="f6 grow no-underline br-pill ph3 pv2 mb2 dib white bg-dark-blue"
              href="#0"
              onClick={e => {
                e.preventDefault()
                fetch('/api/cart/add', {
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                  },
                  method: 'POST',
                  body: JSON.stringify({
                    ...product,
                    ...this.state,
                  }),
                }).then(() => {
                  window.location.href = '/nakupni-kosik'
                })
              }}
            >
              Přidat do košíku
            </a>
          </div>
        </div>
        <div className="fl w-50 w-100-m">
          <div className="fl pa1 w-100-m bb">
            <nav className="pa2 w-100">
              <a
                className={`f6 link dim f5-ns dib mr3 ${
                  tab === 0 ? 'black b' : 'gray'
                }`}
                href="./"
                onClick={e => {
                  e.preventDefault()
                  this.setState(prevState => ({ ...prevState, tab: 0 }))
                }}
              >
                Detailní popis produktu
              </a>
              <a
                className={`f6 link dim f5-ns dib mr3 ${
                  tab === 1 ? 'black b' : 'gray'
                }`}
                href="./"
                onClick={e => {
                  e.preventDefault()
                  this.setState(prevState => ({ ...prevState, tab: 1 }))
                }}
              >
                Dokumenty ke stažení
              </a>
            </nav>
          </div>
          {tab === 0 && (
            <>
              <div
                className="fl w-100"
                dangerouslySetInnerHTML={{ __html: product.description }}
              />
              <div className="fl w-100">
                {map(
                  certificate => (
                    <span className="pa2 tc" key={certificate.url}>
		      <a href={certificate.url}>
                        <img
                          src={`/media/${get('img.filename', certificate)}`}
                          className="br1 h3 w3 dib"
                          alt={certificate.name}
                        />
		     </a>
                    </span>
                  ),
                  product.certificates
                )}
              </div>
            </>
          )}
          {tab === 1 && (
            <div className="fl w-100 pv2">
              {map(medium => {
                const file = medium.file
                return file ? (
                  <div className="fl w-100 pa2" key={medium.name}>
                    <FaFileAlt />
                    &nbsp;-&nbsp;
                    <a href={`/media/${file.filename}`} target="_blank">
		      {medium.name}
		    </a>
                  </div>
                ) : null
              }, product.media)}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default ProductDetail

import find from 'lodash/fp/find'
import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'

export const countPrice = (
  product,
  grammage,
  format,
  amount,
  cartons,
  discountAmount,
  discountPercent
) =>
  pipe(
    get('prices'),
    prices => {
      const price = find({ grammage, format }, prices)
      const prieByGrammage = find({ grammage }, prices)
      const prieByFormat = find({ format }, prices)
      /* eslint-disable no-nested-ternary */
      const countPrice = price
        ? price
        : prieByGrammage
        ? prieByGrammage
        : prieByFormat
      return {
        ...countPrice,
        price:
          cartons < discountAmount
            ? countPrice.packagePrice * cartons * countPrice.carton
            : countPrice.packagePrice * cartons * countPrice.carton -
              Math.ceil(
                ((countPrice.packagePrice * cartons * countPrice.carton) /
                  100) *
                  discountPercent
              ),
      }
    }
  )(product)
